import React from "react";
import { graphql } from "gatsby";
// import Layout from "../components/layout"

import { Helmet } from "react-helmet";

import Header from "../layouts/header";
import Footer from "../layouts/footer";

import Path from "../components/path";
import Cookies from "../components/cookies";
import OilTool from "../components/oilTool";
import { removeHardSpace } from "../components/helpers";

import { currentSections } from "../data/mapSections";
import { sectionsMapMethods } from "../data/sectionsMapMethods";

import { ParallaxProvider } from "react-scroll-parallax";

export default function RenderPages({ data, location }) {
  const sections = data.wpPage.groupPage.sections;
  const footerProps = data.wp.footerSettings;
  const menuItems = data.wpMenu.menuItems.nodes;
  const theme = data.wpPage.groupPage.theme;
  const seo = data.wpPage.seo;
  const footerLinks = data.allWpMenu?.edges;

  const state = location?.state;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "pl",
        }}
      >
        {/* <link rel="stylesheet" href="https://use.typekit.net/fyu0edh.css" /> */}
        <meta charset="UTF-8" />

        <title>{removeHardSpace(seo?.title)}</title>
        <meta name="description" content={seo?.metaDesc} />
        <meta name="keywords" content={seo?.metaKeywords} />

        <meta property="og:title" content={seo?.opengraphTitle} />
        <meta property="og:type" content={seo?.opengraphType} />
        <meta property="og:url" content={seo?.opengraphUrl} />
        <meta property="og:image" content={seo?.opengraphImage?.sourceUrl} />
        <meta property="og:site_name" content={seo?.opengraphSiteName} />

        <meta name="twitter:title" content={seo?.twitterTitle} />
        <meta name="twitter:description" content={seo?.twitterDescription} />
        <meta name="twitter:image" content={seo?.twitterImage} />
      </Helmet>

      <Header links={menuItems} variant={theme} />
      <main>
        <ParallaxProvider>
          {!data?.wpPage?.pageOptions?.disableButtonFly && <OilTool />}

          <Path list={seo?.breadcrumbs} variant={theme} />

          {sections?.map((section, index) => {
            const Tag = currentSections[section.fieldGroupName];

            const mapFunction = sectionsMapMethods[section.fieldGroupName];

            if (Tag)
              return (
                <Tag
                  data={mapFunction(section)}
                  state={state}
                  key={`${section.fieldGroupName}-${index}`}
                  isPath={true}
                />
              );
            return "";
          })}
        </ParallaxProvider>
      </main>
      <Footer data={{ footerProps, footerLinks }} />
      <Cookies />
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      id
      groupPage {
        fieldGroupName
        sections {
          ... on WpPage_Grouppage_Sections_SectionHero {
            buttonLabel
            buttonUrl
            fieldGroupName
            title
            underPhoto {
              fieldGroupName
              list {
                name
                description
                image {
                  altText
                  sourceUrl
                }
              }
            }
            image {
              altText
              srcSet
              sizes
              sourceUrl
              link
            }
            variant
            buttonUrlPage {
              ... on WpPage {
                id
                slug
                title
              }
            }
            theme
            idName
            buttonScrollName
            disableDistributor
            categoryName
            buttonFile {
              mediaType
              mediaItemUrl
            }
          }
          ... on WpPage_Grouppage_Sections_SectionAsortyment {
            idName
            theme
            buttonLabel
            buttonUrl
            buttonUrlPage {
              ... on WpPage {
                id
                slug
                title
                uri
              }
            }
            fieldGroupName
            label
            title
            description
            list {
              fieldGroupName
              listItem {
                description
                fieldGroupName
                label
                title
                links {
                  buttonLabel
                  buttonUrl
                  fieldGroupName
                  buttonUrlPage {
                    ... on WpPage {
                      id
                      slug
                      uri
                    }
                  }
                }
                image {
                  id
                  srcSet
                  sizes
                  sourceUrl
                  mimeType
                  altText
                }
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionRestProducts {
            fieldGroupName
            description
            title
            links {
              fieldGroupName
              item {
                buttonLabel
                buttonUrl
                fieldGroupName
                buttonUrlPage {
                  ... on WpPage {
                    id
                    slug
                    title
                    uri
                  }
                }
              }
            }
            image {
              altText
              sourceUrl
            }
          }
          ... on WpPage_Grouppage_Sections_SectionColorsList {
            description
            fieldGroupName
            idName
            theme
            title
            list {
              item {
                title
                colorsList {
                  item {
                    labelType
                    label
                    image {
                      altText
                      sourceUrl
                      srcSet
                      sizes
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionSimpleContent {
            content
            fieldGroupName
            headline
            theme
            idName
            sectionType
            images {
              item {
                breakpointsList {
                  breakpoint
                  image {
                    altText
                    sizes
                    sourceUrl
                    srcSet
                  }
                }
                imageDefault {
                  altText
                  sizes
                  sourceUrl
                  srcSet
                }
              }
            }
            contentAsBox {
              content
            }
          }
          ... on WpPage_Grouppage_Sections_SectionDownloadList {
            description
            fieldGroupName
            idName
            theme
            title
            list {
              item {
                buttons {
                  buttonLabel
                  buttonScrollName
                  buttonUrl
                  buttonUrlPage {
                    ... on WpPage {
                      id
                      slug
                    }
                  }
                  buttonFile {
                    mediaType
                    mediaItemUrl
                  }
                }
                image {
                  altText
                  sourceUrl
                }
              }
            }
            buttonUrlPage {
              ... on WpPage {
                id
                slug
              }
            }
            buttonUrl
            buttonScrollName
            buttonLabel
            buttonFile {
              mediaType
              mediaItemUrl
            }
          }
          ... on WpPage_Grouppage_Sections_SectionImageAndText {
            buttonLabel
            buttonScrollName
            buttonUrl
            buttonUrlPage {
              ... on WpPage {
                id
                slug
                title
                uri
              }
            }
            parallax
            description
            fieldGroupName
            title
            image {
              id
              altText
              srcSet
              sizes
              sourceUrl
            }
            type
            theme
            imageDirection
            bulllets {
              description
              title
              icon {
                altText
                sourceUrl
              }
              fieldGroupName
            }
            underImageBox {
              fieldGroupName
              columnRight {
                description
                fieldGroupName
                title
              }
              columnLeft {
                description
                fieldGroupName
                title
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionSectionIframeDobierzOlej {
            fieldGroupName
            idName
            theme
            title
          }
          ... on WpPage_Grouppage_Sections_SectionListHorizontal {
            fieldGroupName
            idName
            theme
            title
            list {
              listItem {
                description
                title
                icon {
                  altText
                  sourceUrl
                }
              }
            }
          }

          ... on WpPage_Grouppage_Sections_SectionText {
            fieldGroupName
            content
            idName
            lastUpdate
            theme
            title
            navigation {
              url
              title
            }
          }
          ... on WpPage_Grouppage_Sections_SectionHeroPrimary {
            author
            date
            description
            fieldGroupName
            theme
            title
            image {
              altText
              sourceUrl
              srcSet
              sizes
            }
          }
          ... on WpPage_Grouppage_Sections_SectionHeroAlt {
            description
            fieldGroupName
            highlightedPost {
              ... on WpPost {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    srcSet
                    sizes
                    sourceUrl
                    title
                  }
                }
                categories {
                  nodes {
                    slug
                    name
                    taxonomyName
                  }
                }
              }
            }
            title
          }
          ... on WpPage_Grouppage_Sections_SectionSearchPlaces {
            fieldGroupName
            title
          }
          ... on WpPage_Grouppage_Sections_SectionPlaces {
            description
            fieldGroupName
            title
          }
          ... on WpPage_Grouppage_Sections_SectionContactForm {
            fieldGroupName
            title
            bullets {
              description
              fieldGroupName
              title
              icon {
                altText
                srcSet
                sourceUrl
                sizes
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionContactAndPlaces {
            buttonLabel
            buttonUrl
            title
            type
            fieldGroupName
            description
            buttonUrlPage {
              ... on WpPage {
                id
                slug
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionPeople {
            fieldGroupName
            title
            list {
              fieldGroupName
              listItem {
                title
                description
                fieldGroupName
                image {
                  altText
                  srcSet
                  sourceUrl
                  sizes
                }
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionBannerIntro {
            buttonLabel
            buttonUrl
            buttonUrlPage {
              ... on WpPage {
                id
                slug
              }
            }
            description
            fieldGroupName
            title
            image {
              altText
              sizes
              srcSet
              sourceUrl
            }
            themeColor
            imageDirection
            type
            sectionTypeColor
          }
          ... on WpPage_Grouppage_Sections_SectionCertficates {
            fieldGroupName
            title
            list {
              fieldGroupName
              listItem {
                description
                fieldGroupName
                title
                image {
                  altText
                  srcSet
                  sourceUrl
                  sizes
                }
                urlFile {
                  mediaType
                  mediaItemUrl
                }
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionQuality {
            fieldGroupName
            title
            list {
              fieldGroupName
              listItem {
                title
                fieldGroupName
                description
                icon {
                  altText
                  srcSet
                  sizes
                  sourceUrl
                }
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionHeroSpecial {
            fieldGroupName
            idName
            theme
            title
            image {
              altText
              sourceUrl
            }
            imageAroundTitle {
              altText
              sourceUrl
            }
          }
          ... on WpPage_Grouppage_Sections_SectionListPosts {
            fieldGroupName
            title
            list {
              slug
              id
            }
            filtersVisible
          }
          ... on WpPage_Grouppage_Sections_SectionListPostsIntro {
            buttonLabel
            buttonMoreLabel
            buttonUrl
            buttonUrlPage {
              ... on WpPage {
                id
                slug
                title
              }
            }
            title
            subTitle
            fieldGroupName
            typeList
            theme
          }
          ... on WpPage_Grouppage_Sections_SectionNewsletter {
            fieldGroupName
            theme
          }
          ... on WpPage_Grouppage_Sections_SectionNumbers {
            fieldGroupName
            theme
            list {
              title
              number
              fieldGroupName
            }
          }
          ... on WpPage_Grouppage_Sections_SectionMapHorizontal {
            fieldGroupName
          }
          ... on WpPage_Grouppage_Sections_SectionBoxesAsLinks {
            description
            fieldGroupName
            theme
            title
            boxes {
              fieldGroupName
              theme
              title
              url
              urlPage {
                ... on WpPage {
                  id
                  slug
                }
              }
              icon {
                altText
                sourceUrl
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionChangingListImage {
            buttonLabel
            buttonUrl
            buttonUrlPage {
              ... on WpPage {
                id
                slug
              }
            }
            subTitle
            theme
            title
            list {
              description
              title
              image {
                altText
                sourceUrl
              }
              icon {
                disabled {
                  altText
                  sourceUrl
                }
                actived {
                  altText
                  sourceUrl
                }
              }
            }
            fieldGroupName
            buttonScrollName
            idName
          }
          ... on WpPage_Grouppage_Sections_SectionImageAndCarousel {
            fieldGroupName
            theme
            title
            list {
              author
              description
            }
            image {
              altText
              sizes
              sourceUrl
              srcSet
            }
          }
          ... on WpPage_Grouppage_Sections_SectionOfferIntro {
            fieldGroupName
            title
            offerCategory {
              link
              id
              slug
              databaseId
              taxonomyName
            }
          }
          ... on WpPage_Grouppage_Sections_SectionListWithIconAndText {
            fieldGroupName
            theme
            title
            list {
              description
              title
              icon {
                altText
                sourceUrl
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionTrainingForm {
            fieldGroupName
            title
          }
          ... on WpPage_Grouppage_Sections_SectionTrainingListPosts {
            fieldGroupName
            title
          }
          ... on WpPage_Grouppage_Sections_SectionJobsListPosts {
            fieldGroupName
            title
          }
          ... on WpPage_Grouppage_Sections_SectionJobsForm {
            fieldGroupName
            title
          }
          ... on WpPage_Grouppage_Sections_SectionBannerImageAndText {
            fieldGroupName
            buttonLabel
            buttonUrl
            title
            buttonUrlPage {
              ... on WpPage {
                id
                slug
                title
              }
            }
            description
            image {
              altText
              srcSet
              sourceUrl
              sizes
            }
          }
          ... on WpPage_Grouppage_Sections_SectionTextIntro {
            description
            fieldGroupName
            title
            theme
            buttons {
              buttonLabel
              buttonUrl
              buttonUrlPage {
                ... on WpPage {
                  id
                  title
                  slug
                }
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionEbookIntro {
            expertLabel
            fieldGroupName
            image {
              altText
              srcSet
              sourceUrl
              sizes
            }
            button {
              target
              title
              url
            }
            expertImage {
              altText
              srcSet
              sourceUrl
              sizes
            }
            title
            list {
              fieldGroupName
              label
            }
          }
          ... on WpPage_Grouppage_Sections_SectionFullwidthImage {
            fieldGroupName
            isDecor
            theme
            image {
              altText
              srcSet
              sourceUrl
              sizes
            }
          }
          ... on WpPage_Grouppage_Sections_SectionImagesMobileCarousel {
            fieldGroupName
            list {
              image {
                altText
                srcSet
                sourceUrl
                sizes
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionBannerPerson {
            fieldGroupName
            title
            label
            description
            content
            image {
              altText
              srcSet
              sourceUrl
              sizes
            }
          }
          ... on WpPage_Grouppage_Sections_SectionHeadlineWithSimpleTextList {
            fieldGroupName
            additionalText
            description
            button {
              target
              title
              url
            }
            label
            image {
              altText
              srcSet
              sourceUrl
              sizes
            }
            theme
            title
            variant
            list {
              text
              fieldGroupName
            }
            padding
          }
          ... on WpPage_Grouppage_Sections_SectionHeadlineWithGridList {
            description
            fieldGroupName
            label
            theme
            title
            isShape
            list {
              label
              text
              icon {
                altText
                srcSet
                sourceUrl
                sizes
              }
            }
          }
          ... on WpPage_Grouppage_Sections_SectionNewsletterEbook {
            fieldGroupName
            title
            list {
              text
            }
            image {
              altText
              srcSet
              sourceUrl
              sizes
            }
          }
          ... on WpPage_Grouppage_Sections_SectionEbookHero {
            description
            fieldGroupName
            title
            image {
              altText
              sizes
              sourceUrl
              srcSet
            }
          }
          ... on WpPage_Grouppage_Sections_SectionListWithIconAndTextWithContact {
            fieldGroupName
            theme
            title
            list {
              title
              description
              icon {
                altText
                sourceUrl
              }
            }
            contactBox {
              image {
                altText
                sizes
                sourceUrl
                srcSet
              }
              columns {
                description
                title
              }
            }
          }
        }
        theme
      }
      guid
      title
      slug
      uri
      status
      isFrontPage
      seo {
        title
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
        }
        opengraphTitle
        opengraphSiteName
        opengraphType
        opengraphUrl
        opengraphImage {
          altText
          sourceUrl
        }
        metaKeywords
        metaDesc
        breadcrumbs {
          text
          url
        }
      }
      pageOptions {
        disableView
        disableButtonFly
      }
    }
    wp {
      generalSettings {
        url
        title
        description
        language
      }
      footerSettings {
        settings {
          description
          address
          address2
          copyright
          createdBy
          socials {
            fieldGroupName
            icon
            url
          }
        }
      }
    }
    wpMenu(name: { in: "Menu top" }) {
      name
      menuItems {
        nodes {
          id
          label
          order
          url
          path
          target
          parent {
            id
          }
          parentId
          connectedNode {
            node {
              ... on WpPage {
                id
                groupPage {
                  menuIcon {
                    altText
                    sourceUrl
                  }
                }
                pageOptions {
                  label
                  disableView
                  disableButtonFly
                }
              }
            }
          }
        }
      }
      parent {
        id
        ... on WpPage {
          id
        }
      }
      nodeType
    }

    allWpMenu {
      edges {
        node {
          id
          count
          name
          slug
          menuItems {
            nodes {
              order
              title
              uri
              path
              parentId
              id
              label
              connectedNode {
                node {
                  ... on WpPage {
                    id
                    pageOptions {
                      label
                      disableView
                      disableButtonFly
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
